<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CCard class="object-table-wrapper">
        <CCardHeader>
          <strong>{{ $t("smart.smartContracts") }}</strong>
          <div class="card-header-actions">
            <CDropdown :toggler-text="$t('common.add')" color="success">
              <CDropdownItem @click="createClicked">{{
                $t("smart.create")
              }}</CDropdownItem>
              <CDropdownItem @click="importClicked">{{
                $t("smart.import")
              }}</CDropdownItem>
            </CDropdown>
          </div>
        </CCardHeader>
        <CCardBody v-if="allSmartContracts.length > 0">
          <CDataTable
            striped
            :items="allSmartContracts"
            :fields="fields"
            :items-per-page="10"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            hover
            clickable-rows
            responsive
          >
            <template #name="{ item }">
              <td @click="toSmartContract(item)">
                {{ item.name | capitalizeFirst }}
              </td>
            </template>

            <template #blockchain_version="{ item }">
              <td>
                <img
                  :src="getTypeSrc(item.blockchain_version)"
                  style="height: 24px"
                />
              </td>
            </template>

            <template #lang="{ item }">
              <td>
                {{ item.lang }}
              </td>
            </template>

            <template #git_remote_path="{ item }">
              <td>
                <CLink :href="item.git_remote_path">{{
                  item.git_remote_path
                }}</CLink>
                ({{ item.git_branch }})
              </td>
            </template>

            <template #last_commit="{ item }">
              <td>
                {{ item.last_commit }}
              </td>
            </template>

            <template #update_status="{ item }">
              <td>
                <CBadge :color="contractStatus[item.update_status].style">
                  {{ contractStatus[item.update_status].text }}
                </CBadge>
              </td>
            </template>
            <template #delete="{ item }">
              <td>
                <CButton
                  @click.stop.prevent="deleteSmartContract(item)"
                  size="lg"
                  class="p-0 m-0"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import utilMessages from "../../../utils/messages";
import { NetworkTypes } from "../networks/constants";
import { SmartContractStatus } from "./constants";

export default {
  name: "SmartContracts",
  props: {},

  data() {
    return {
      contractStatus: SmartContractStatus,
      netTypes: NetworkTypes,
      fields: [
        {
          key: "name",
          label: this.$t("smart.name"),
          _classes: "font-weight-bold",
        },
        { key: "blockchain_version", label: this.$t("smart.type") },
        { key: "lang", label: this.$t("smart.language") },
        { key: "git_remote_path", label: "Git-path" },
        { key: "last_commit", label: "Commit" },
        { key: "update_status", label: this.$t("smart.status") },
        { key: "delete", label: " " },
      ],
      activePage: 1,
    };
  },

  async mounted() {
    await this.fetchSmartContracts();
  },
  computed: {
    ...mapGetters(["allSmartContracts", "getPendingTopic"]),
    ...mapState({
      pendingTopic: (state) => state.smartContracts._pendingTopic,
    }),
  },

  methods: {
    ...mapActions(["setPendingTopic", "subscribeTopic"]),
    getTypeSrc(netType) {
      switch (netType) {
        case this.netTypes.GOSMART10.code:
          return this.netTypes.GOSMART10.src;
        case this.netTypes.HLF20.code:
          return this.netTypes.HLF20.src;
        case this.netTypes.HLF14.code:
          return this.netTypes.HLF14.src;
        case this.netTypes.HLF22.code:
          return this.netTypes.HLF22.src;
      }
    },

    async fetchSmartContracts() {
      try {
        await this.$store.dispatch("fetchSmartContracts");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    toSmartContract(item) {
      this.$router.push({ path: `/smart-contracts/${item.id}` });
    },

    createClicked() {
      this.$router.push({ path: `/smart-contracts/create` });
    },
    importClicked() {
      this.$router.push({ path: `/smart-contracts/import` });
    },
    async deleteSmartContract(item) {
      try {
        await this.$store.dispatch("deleteSmartContract", { id: item.id });
        this.$toast.success(this.$i18n.t("smart.smartContractDeleted"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
  created() {
    if (this.getPendingTopic === "") {
      return;
    }

    this.subscribeTopic({
      topicId: this.getPendingTopic,
      handler: this.processTopic,
    });
  },
  processTopic() {
    this.setPendingTopic("");
    this.fetchSmartContracts();
  },
};
</script>

<style scoped></style>
